export const New = () => {
  return (
    <div className="new">
      <p className="new__title">নতুন কি?</p>
      <p className="new__description">আপডেট করা বোনাস এবং প্রচার বিভাগ</p>
      <p className="new__description">
        মানচিত্রে নিকটতম ব্যাঙ্ক শাখা, এটিএম বা টার্মিনালের অবস্থান যোগ করা
        হয়েছে৷
      </p>
      <p className="new__description">
        ফেস আইডি এবং টাচ আইডির জন্য সমর্থন যোগ করা হয়েছে।
      </p>
      <p className="new__description">
        কিছু Android 10 ব্যবহারকারীদের জন্য পুশ বিজ্ঞপ্তিগুলির সাথে একটি সমস্যা
        সমাধান করা হয়েছে।
      </p>
      <p className="new__description">
        আমরা একটি সমস্যা সমাধান করেছি যেখানে Android ব্যবহারকারীদের জন্য সেটিংসে
        পাঠ্যটি খুব ছোট ছিল।
      </p>
    </div>
  );
};
